<template>
    <nav>
        <font-awesome-icon class="bx" :icon="['fas', 'bars']" />
        <form action="#">
            <div class="form-input">
                <!-- <input type="search" placeholder="Tìm kiếm..." />
                <button type="submit" class="search-btn">
                    <font-awesome-icon class="bx" :icon="['fas', 'magnifying-glass']" />
                </button> -->
            </div>
        </form>
        <a class="notification" @click="btnShowNotification">
            <!-- <font-awesome-icon class="bx" :icon="['fas', 'bell']" />
            <span class="num" v-if="numberNoti != null">{{ numberNoti }}</span> -->

            <ul
                class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications show"
                data-popper-placement="bottom-end"
                style="
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px;
                    transform: translate3d(7.2px, 23.2px, 0px);
                    max-height: 500px;
                    overflow-y: auto;
                "
                v-if="showBoxNotif"
                v-click-away="() => (showBoxNotif = false)"
            >
                <li class="dropdown-header">Lịch sử</li>
                <template v-for="(item, index) in arrNotification" :key="index">
                    <li>
                        <hr class="dropdown-divider" />
                    </li>

                    <li class="notification-item">
                        <font-awesome-icon
                            :icon="['fas', 'comment-dots']"
                            style="margin: 0 15px 0 10px; font-size: 24px"
                        />
                        <div>
                            <h4>{{ item.num }} phản hồi chưa xem</h4>
                            <p>{{ calcDateTime(item.date) }} trước</p>
                        </div>
                    </li>
                </template>
            </ul>
        </a>
        <a href="#" class="profile">
            <img src="../../../assets/img/admin-avatar.png" />
        </a>
    </nav>
</template>
<script>
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
// import { EventBus } from '@/js/EventBus';
export default {
    name: 'HeaderAdmin',
    methods: {
        btnLogout() {
            this.arrNotification = {};
            localStorage.removeItem('adminInfor');

            this.$router.push('/');
            setTimeout(() => {
                location.reload();
            }, 1000);
        },

        btnShowNotification() {
            this.showBoxNotif = !this.showBoxNotif;
            if (!this.isShowedNotif) {
                this.isShowedNotif = true;
            }
        },

        calcDateTime(createdDate) {
            // Lấy thời điểm hiện tại
            const now = moment();

            // Lấy thời điểm truyền vào
            const date = moment(createdDate);

            // Tính toán khoảng thời gian từ thời điểm đó đến hiện tại
            const duration = moment.duration(now.diff(date));

            // Xuất ra chuỗi mô tả khoảng thời gian
            return duration.humanize();
        },

        // seenFeedbackHandler(checked) {
        //     if (checked === true) {
        //         this.numberNoti = null;
        //     }
        //     EventBus.off('seenFeedback', this.seenFeedbackHandler);
        // },
    },
    // beforeUnmount() {
    //     EventBus.off('seenFeedback', this.seenFeedbackHandler);
    // },
    // created() {
    //     const storage = localStorage.getItem('adminInfor');
    //     if (storage !== null) {
    //         this.arrNotification = JSON.parse(storage);
    //         console.log(
    //             'Storage: ' +
    //                 this.arrNotification[this.arrNotification.length - 1].watch +
    //                 ' ' +
    //                 this.arrNotification[this.arrNotification.length - 1].num,
    //         );
    //         if (!this.arrNotification[this.arrNotification.length - 1].watch) {
    //             this.numberNoti = this.arrNotification[this.arrNotification.length - 1].num;
    //         }
    //     }

    //     EventBus.on('seenFeedback', this.seenFeedbackHandler);
    // },

    data() {
        return {
            // accountAdmin: false,
            arrNotification: [],
            showBoxUser: false,
            orderList: [],

            descriptionTime: null,

            showBoxNotif: false,

            isShowedNotif: false,
            numberNoti: null,
        };
    },
};
</script>
<style>
@import url(./header.css);
/* Css header admin */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.header-left {
    display: flex;
    align-items: center;
    column-gap: 24px;
}

.name-company select {
    background-color: transparent;
    border: none;
    outline: none;
    color: #212121;
    font-weight: 500;
}

.header-right {
    display: flex;
    align-items: center;
    column-gap: 24px;
}

.isDisplayNotif {
    visibility: visible;
    opacity: 0;
    display: none;
}
</style>
