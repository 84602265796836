const MEnum = {
    KEY_CODE: {
        ENTER: 13, //Phím enter
        ROW_UP: 38, //Phím mũi tên lên
        ROW_DOWN: 40, //Phím mũi tên xuống
        TAB: 9, //Phím tab
        CTRL: 17, //Phím Ctrl
        S: 83, //Phím chữ S
        SHIFT: 16, //Phím Shift
        ESC: 27, //Phím Esc
    },
    FORM_MODE: {
        ADD: 1, //Thêm mới
        UPDATE: 2, //Sửa
        VIEW: 3, //Xem
    },
    GENDER: {
        MALE: 0, //Nam
        FEMALE: 1, //Nữ
        OTHER: 2, //Chưa xác định
    },
    RESULT_GAME: {
        WIN: 2, //Thắng
        LOSE: 1, //Thua
        DRAW: 0, //Hòa
    },

    FRIEND_GAME: {
        FACEBOOK: 1, //bạn facebook
        INGAME: 2, //bạn trong game
        NO_FRIEND: 3, //2 người không phải là bạn bè
    },
    MODE_PLAY: {
        STANDARD: 0,
        TOURNAMENT: 1,
        BLITZ: 2,
    },
};
export default MEnum;
