<template>
    <div class="chart-container" ref="chartContainer"></div>
</template>

<script>
import * as echarts from '../../js/echarts.min.js';

export default {
    name: 'MChartDoughnutEcharts',
    props: ['options'],
    watch: {
        options: {
            deep: true,
            handler(newOptions) {
                this.chart.setOption(newOptions, true);
            },
        },
    },
    methods: {
        buildChart() {
            this.chart = echarts.init(this.$refs.chartContainer);
            this.chart.setOption(this.options, true);
        },
    },
    mounted() {
        this.buildChart();
    },
    data() {
        return {
            chart: {},
        };
    },
};
</script>

<style>
.chart-container {
    width: 550px;
    height: 400px;
}
</style>
