<template>
    <div class="container-login">
        <div id="logo">
            <h1><i>VIVU GLOBAL</i></h1>
        </div>
        <section class="stark-login">
            <div class="form-input-login">
                <div id="fade-box">
                    <input
                        type="text"
                        v-model="userProfile.username"
                        name="username"
                        id="username"
                        placeholder="Tài khoản"
                        required
                    />
                    <input type="password" v-model="userProfile.password" placeholder="Mật khẩu" required />

                    <button @click="btnLogin">Đăng nhập</button>
                </div>
            </div>
            <div class="hexagons">
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <br />
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <br />
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>

                <br />
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <br />
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
                <span>&#x2B22;</span>
            </div>
        </section>

        <div id="circle1">
            <div id="inner-cirlce1">
                <h2></h2>
            </div>
        </div>

        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
    <!-- <MLoadingClient v-if="showLoadingClient"></MLoadingClient> -->
</template>

<script>
import axios from 'axios';

export default {
    name: 'LoginAdmin',
    methods: {
        btnLogin() {
            // this.showLoadingClient = true;
            if (this.isClickLogin) return;
            this.isClickLogin = true;
            axios({
                url: `${this.$MResource.API_LOGIN}/login`,
                method: 'POST',
                data: this.userProfile,
                withCredentials: true,
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((response) => {
                    // if (response.data.message.total > 0) {
                    //     const infor = localStorage.getItem('adminInfor');
                    //     if (infor != null && infor != undefined) {
                    //         const list = JSON.parse(infor);
                    //         if (
                    //             list[list.length - 1].num != response.data.message.total &&
                    //             list[list.length - 1].watch
                    //         ) {
                    //             list.push({ num: response.data.message.total, watch: false, date: new Date() });
                    //             if (list.length > 20) list.shift();
                    //             localStorage.setItem('adminInfor', JSON.stringify(list));
                    //         }
                    //     } else {
                    //         localStorage.setItem(
                    //             'adminInfor',
                    //             JSON.stringify([{ num: response.data.message.total, watch: false, date: new Date() }]),
                    //         );
                    //     }
                    // }
                    localStorage.setItem('totalFeedback', response.data.message.total);

                    this.$router.replace('/').catch((err) => {
                        console.error('Lỗi điều hướng: ', err);
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                    setTimeout(() => {
                        this.showLoadingClient = false;
                    }, 700);
                })
                .catch((error) => {
                    console.error('error: ' + error);
                    this.$MToastMessage({
                        titleToast: this.$MResource.VI.TOAST.TITLE_ERROR,
                        messageToast: 'Tên dăng nhập hoặc mật khẩu không đúng. Vui lòng thử lại!',
                        showToastMessage: true,
                        typeToast: 'errorToast',
                    });
                });
            // axios.get('http://localhost:5001/admin/logout', this.userProfile).then(() => {
            //     console.log('success');
            //     this.$router.push('/');
            // });
        },
    },
    data() {
        return {
            userProfile: {},
            isClickLogin: false,
            // showLoadingClient: false,
        };
    },
};
</script>

<style scoped>
@import url('./login.css');
</style>
