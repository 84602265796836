<template>
    <div class="container-admin" v-if="isLogined == true">
        <TheSidebar></TheSidebar>
        <ContentAdmin></ContentAdmin>
    </div>
    <LoginAdmin v-else></LoginAdmin>
</template>

<script>
import axios from 'axios';
// import { useCookies } from 'vue3-cookies';
import ContentAdmin from './layout/home/ContentAdmin.vue';
import TheSidebar from './layout/home/TheSidebar.vue';
import LoginAdmin from './view/login/Login.vue';

export default {
    name: 'App',
    components: {
        ContentAdmin,
        TheSidebar,
        LoginAdmin,
    },
    created() {
        axios({
            url: `${this.$MResource.API_LOGIN}/login`,
            method: 'GET',
            withCredentials: true,
            headers: {
                'Content-type': 'application/json',
            },
        }).then((res) => {
            console.log('res tra ve: ', res.data.total);
            this.isLogined = res.data.loggedIn;
            if (this.isLogined) {
                // if (res.data.total > 0) {
                //     const infor = localStorage.getItem('adminInfor');
                //     if (infor != null && infor != undefined) {
                //         const list = JSON.parse(infor);
                //         if (list[list.length - 1].num != res.data.total && list[list.length - 1].watch) {
                //             list.push({ num: res.data.total, watch: false, date: new Date() });
                //             if (list.length > 20) list.shift();
                //             localStorage.setItem('adminInfor', JSON.stringify(list));
                //         }
                //     } else {
                //         localStorage.setItem(
                //             'adminInfor',
                //             JSON.stringify([{ num: res.data.total, watch: false, date: new Date() }]),
                //         );
                //     }
                // }

                localStorage.setItem('totalFeedback', res.data.total);

                // this.$router.replace({ path: '/', query: {} }).catch((err) => {
                //     console.error('Lỗi điều hướng: ', err);
                // });
            } else {
                this.$router.replace({ path: '/login', query: {} }).catch((err) => {
                    console.error('Lỗi điều hướng: ', err);
                });
            }
        });
    },
    data() {
        return {
            isLogined: false,
        };
    },
};
</script>

<style>
@import url('./css/main.css');

.container-admin {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
}
</style>
