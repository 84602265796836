<template>
    <section id="sidebar">
        <a href="#" class="brand">
            <img class="sidabar-icon" src="../../assets/logo.png" alt="" width="40px" />
            <span class="text">Connect4</span>
        </a>
        <ul class="side-menu top">
            <li>
                <router-link class="sidebar-item" to="/">
                    <font-awesome-icon class="bx" :icon="['fas', 'gauge']" />
                    <span class="text">Dashboard</span>
                </router-link>
            </li>
            <li>
                <router-link class="sidebar-item" to="/account">
                    <font-awesome-icon class="bx" :icon="['fas', 'user']" />
                    <span class="text">Quản lý user</span>
                </router-link>
            </li>
            <li>
                <router-link class="sidebar-item" to="/history">
                    <font-awesome-icon class="bx" :icon="['far', 'clock']" />
                    <span class="text">Quản lý lịch sử</span>
                </router-link>
            </li>

            <li>
                <router-link class="sidebar-item" to="/tracking">
                    <font-awesome-icon class="bx" :icon="['fas', 'flag-checkered']" />
                    <span class="text">Quản lý theo dõi</span>
                </router-link>
            </li>
            <li>
                <router-link class="sidebar-item" to="/bot">
                    <font-awesome-icon class="bx" :icon="['fas', 'robot']" />
                    <span class="text">Quản lý bot</span>
                </router-link>
            </li>
            <li @click="seenFeedback" class="feedback-btn">
                <router-link class="sidebar-item" to="/feedback">
                    <font-awesome-icon class="bx" :icon="['fas', 'message']" />
                    <span class="text">Phản hồi</span>
                </router-link>
                <div class="notification" v-if="numberNoti != null">
                    <font-awesome-icon class="bx" :icon="['fas', 'bell']" />
                    <span class="num">{{ numberNoti }}</span>
                </div>
            </li>
            <li>
                <router-link class="sidebar-item" to="/report">
                    <font-awesome-icon class="bx" :icon="['fas', 'bug']" />
                    <span class="text">Báo cáo</span>
                </router-link>
            </li>
            <li>
                <router-link class="sidebar-item" to="/maintain">
                    <font-awesome-icon class="bx" :icon="['fas', 'clock']" />
                    <span class="text">Bảo trì hệ thống</span>
                </router-link>
            </li>
        </ul>
        <ul class="side-menu">
            <li>
                <router-link class="sidebar-item" to="/setting">
                    <font-awesome-icon class="bx" :icon="['fas', 'gear']" />
                    <span class="text">Cài đặt</span>
                </router-link>
            </li>
            <li>
                <div class="sidebar-item logout" @click="btnLogout">
                    <font-awesome-icon class="bx" icon="fa-solid fa-right-from-bracket" style="color: var(--red)" />
                    <span class="text">Đăng xuất</span>
                </div>
            </li>
        </ul>
    </section>
    <MLoadingClient v-if="showLoadingClient"></MLoadingClient>
    <!-- </div> -->
</template>
<script>
import axios from 'axios';
// import { EventBus } from '@/js/EventBus';
export default {
    name: 'TheSidebar',
    methods: {
        btnLogout() {
            this.showLoadingClient = true;
            axios({
                url: `${this.$MResource.API_LOGIN}/logout`,
                method: 'GET',
                withCredentials: true,
                headers: {
                    'Content-type': 'application/json',
                },
            }).then(() => {
                this.$router.replace({ path: '/login', name: 'login' });
                setTimeout(() => {
                    location.reload();
                }, 500);
                setTimeout(() => {
                    this.showLoadingClient = false;
                }, 700);
            });
        },

        seenFeedback(event) {
            event.preventDefault();
            // const storage = localStorage.getItem('adminInfor');
            // if (storage !== null && storage != undefined) {
            //     if (!storage[storage.length - 1].watch) EventBus.emit('seenFeedback', true);
            // }

            this.numberNoti = null;
            localStorage.setItem('totalFeedback', 0);
        },
    },
    created() {
        // const storage = localStorage.getItem('adminInfor');
        // if (storage !== null && storage != undefined) {
        //     this.arrNotification = JSON.parse(storage);

        //     if (!this.arrNotification[this.arrNotification.length - 1].watch) {
        //         this.numberNoti = this.arrNotification[this.arrNotification.length - 1].num;
        //     }
        // }
        const storage = JSON.parse(localStorage.getItem('totalFeedback'));
        if (storage !== null && storage != undefined && storage > 0) {
            this.numberNoti = storage;
        }
    },
    data() {
        return {
            arrNotification: [],
            showLoadingClient: false,
            numberNoti: null,
        };
    },
};
</script>
<style scoped>
#sidebar .side-menu .router-link-active,
.router-link-exact-active {
    background: var(--grey) !important;
    position: relative !important;
}
.separation-line {
    margin: 5px 20px;
    border-top: 0.5px solid #fff;
    opacity: 0.3;
}

.feedback-btn {
    display: flex;
    align-items: center;
    position: relative;
}

.notification {
    font-size: 20px;
    /* position: relative; */
    position: absolute;
    right: 25px;
}
.notification .num {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--light);
    background: var(--red);
    color: var(--light);
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
